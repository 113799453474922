/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-12-11",
    versionChannel: "nightly",
    buildDate: "2023-12-11T00:16:55.528Z",
    commitHash: "512a03815da8696b9a997f7b5eb6922005d110d5",
};
